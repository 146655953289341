<template>
  <div>
    <div class="">
      <div class="ui form">
        <div class="field" :class="{ error: !title.valid }">
          <label for="title">테이블 제목</label>
          <input type="text" id="title" v-model="title.value" />
        </div>
        <div class="field" :class="{ error: !description.valid }">
          <label for="description">메모</label>
          <textarea type="text" id="description" v-model="description.value" />
        </div>

        <div class="ui right aligned fluid container">
          <m-btn @click="createTable" color="primary"> 저장 </m-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MBtn from "@/components/common/MBtn"
import TableService from "@/service/TableService"
import lodash from "lodash"
import { toast } from "@/utils/SemanticUtil"
import PageMixin from "@/mixins/PageMixin"
import { Page } from "@/define/Page"

export default {
  name: "NewTableForm",
  components: {
    MBtn,
  },
  data: () => {
    return {
      title: { value: null, valid: true },
      description: { value: null, valid: true },
    }
  },
  mixins: [PageMixin],
  computed: {
    titleTrim() {
      return lodash.trim(this.title.value)
    },
  },
  methods: {
    validate() {
      this.title.valid = !!this.titleTrim
      return this.title.valid && true
    },
    createTable() {
      console.log("submit")
      if (this.validate()) {
        this.$store.commit("page/onLoader")
        TableService.createTable(this.titleTrim, this.description.value)
          .then(({ data }) => {
            console.debug(data)
            const tableId = data.data
            this.$router.push({ name: "TableEditor", params: { id: tableId } })
          })
          .catch((error) => {
            toast(error, "error")
          })
          .finally(() => {
            this.$store.commit("page/offLoader")
          })
      }
    },
  },
  created() {
    this.$store.commit("page/changeTitle", Page.NewTableForm.title)
  },
}
</script>

<style scoped></style>
